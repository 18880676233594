import clientApollo from './index';
import { CONSTANTS } from '../../constants/Constants';

export async function destroyTrack(MUTATION, id) {
  const client = clientApollo();

  try {
    const response = await client.mutate({
      mutation: MUTATION,
      variables: {
        id,
      },
    });

    return JSON.parse(JSON.stringify(response.data.destroyTrack));
  } catch (error) {
    console.error(error);
    throw new Error(CONSTANTS.FAILED_DESTROY_TRACK);
  }
}
