import React from 'react';
import NotificationsSystem, { atalhoTheme, useNotifications } from 'reapop';

function TopNotification() {
  const { notifications, dismissNotification } = useNotifications();

  return (
    <div>
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={dismissNotification}
        theme={atalhoTheme}
      />
    </div>
  );
}

export default TopNotification;
