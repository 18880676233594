import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import Days from './Days';

const Weeks = ({ data, setTrack, fetchTracksTable }) => {
  const [weeks] = useState(data);

  return weeks.map(({
    week: {
      start_at, end_at, time, days,
    },
  }, index) => {
    const startDate = moment(start_at).format('MMM DD');
    const endDate = moment(end_at).format('MMM DD');

    return (
      <Row className="mt-2" key={index}>
        <Col xs="6">
          {`${endDate} - ${startDate}`}
        </Col>
        <Col className="text-right" xs="6">
          Week Total:
          {' '}
          <span className="time">{time}</span>
        </Col>
        <Days
          days={days}
          setTrack={setTrack}
          fetchTracksTable={fetchTracksTable}
        />
      </Row>
    );
  });
};

export default Weeks;
