import clientApollo from './index';
import { CONSTANTS } from '../../constants/Constants';

export async function updateInterval(MUTATION, id, data) {
  const client = clientApollo();

  try {
    const response = await client.mutate({
      mutation: MUTATION,
      variables: {
        id,
        ...data,
      },
    });

    return JSON.parse(JSON.stringify(response.data.updateInterval));
  } catch (error) {
    console.error(error);
    throw new Error(CONSTANTS.FAILED_UPDATE_INTERVAL);
  }
}
