import moment from "moment";

export const MOMENT_TIME_ZONES_EQUIVALENCE = {
  'Cairo': 'Africa/Cairo',
  'Buenos Aires': 'America/Argentina/Buenos_Aires',
  'Bogota': 'America/Bogota',
  'Central America': 'America/Guatemala',
  'Quito': 'America/Lima',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Mexico City': 'America/Mexico_City',
  'Eastern Time (US & Canada)': 'America/New_York',
  'Brasilia': 'America/Sao_Paulo',
  'Tokyo': 'Asia/Tokyo',
  'Azores': 'Atlantic/Azores',
  'Cape Verde Is.': 'Atlantic/Cape_Verde',
  'Mid-Atlantic': 'Etc/GMT+2',
  'Brisbane': 'Australia/Brisbane',
  'Darwin': 'Australia/Darwin',
  'Sydney': 'Australia/Sydney',
  'UTC': 'UTC',
  'Amsterdam': 'Europe/Amsterdam',
  'Bern': 'Europe/Berlin',
  'Dublin': 'Europe/Dublin',
  'London': 'Europe/London',
  'Madrid': 'Europe/Madrid',
  'Volgograd': 'Europe/Moscow',
  'Paris': 'Europe/Paris',
  'Wellington': 'Pacific/Auckland'
}

export const localDate = (datetime, timezone = null, format = true) => {
  const userTimeZone = getUserTimeZone(timezone);
  const date = moment.tz(datetime, userTimeZone);

  return format ? date.format("DD-MM-YYYY") : date.format("YYYY-MM-DD");
};

export const localTime = (datetime, timezone = null) => {
  const userTimeZone = getUserTimeZone(timezone);
  const date = moment.tz(datetime, userTimeZone);

  return date.format("HH:mm");
};

export const utcDateTime = (datetime, timezone = null) => {
  const userTimeZone = getUserTimeZone(timezone);
  return moment.tz(datetime, "DD-MM-YYYY HH:mm", userTimeZone).utc();
};

export const getCurrentTimeByTimeZone = (timezone = null) => {
  return moment.tz(getUserTimeZone(timezone));
};

const getUserTimeZone = (timezone) => {
  return MOMENT_TIME_ZONES_EQUIVALENCE[timezone || getUserTimezoneFromInput() || "UTC"];
};

const getUserTimezoneFromInput = () => {
  const userTimezoneInput = document.getElementById("user_timezone");
  return userTimezoneInput?.value;
};
