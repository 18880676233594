import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { NotificationsProvider, setUpNotifications } from 'reapop';
import Track from '../track/Track';
import TrackerTable from './TrackerTable';
import LoadingSpinner from '../utils/Loading';
import TopNotification from '../utils/Notification';
import { useFetch } from '../../hooks/useFetch';
import { GET_TRACKS } from '../../graphql/queries';

setUpNotifications({
  defaultProps: {
    position: 'top-right',
    dismissible: true,
    dismissAfter: 4000,
  },
});

const initialTrack = {
  description: '',
  project_id: '',
  name: '',
  status: 'unstarted',
};

const TimeTracker = () => {
  const { data, isLoading, refetch } = useFetch(GET_TRACKS);
  const [track, setTrack] = useState(initialTrack);

  return (
    <NotificationsProvider>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container fluid className="time-tracker-container">
          <TopNotification />
          <Track
            fetchTracksTable={refetch}
            track={track}
          />
          <TrackerTable
            data={data}
            setTrack={setTrack}
            fetchTracksTable={refetch}
          />
        </Container>
      )}
    </NotificationsProvider>
  );
}

export default TimeTracker;
