import { gql } from '@apollo/client';

export const GET_TRACKS = gql`
  {
    user {
      id
      weeks
    }
  }
`;

export const GET_TRACKS_WITH_PROJECTS = gql`
  {
    user {
      id
      name
      tracks {
        nodes {
          id
          name
        }
      }
      projects {
        id
        name
        client {
          id
          fullName
        }
      }
   }
  }
`;

export const GET_PROJECTS = gql`
{
  user {
    projects {
      id
      name
    }
  }
}
`;

export const GET_CLIENTS = gql`
{
  user {
    projects {
      id
      name
      client {
        id
        active
        fullName
        email
      }
    }
  }
}
`;

export const CREATE_INTERVAL = gql`
  mutation createInterval($track_id: ID!, $start_at: ISO8601DateTime!, $end_at: ISO8601DateTime!, $description: String!) {
    createInterval(trackId: $track_id, startAt: $start_at, endAt: $end_at, description: $description) {
      interval {
        id
        startAt
        endAt
        track {
          id
          name
        }
      }
      errors
    }
  }
`;

export const CREATE_TRACK = gql`
  mutation createTrack($name: String!, $description: String!, $project_id: ID!) {
    createTrack(name: $name, description: $description, projectId: $project_id) {
      track {
        name
        description
        status
        project {
          id
        }
      }
      errors
    }
  }
`;

export const CREATE_TRACK_WITH_INTERVAL = gql`
  mutation createTrack($name: String!, $description: String!, $project_id: ID!, $status: String!, $intervals: [JSON!]){
    createTrack(name: $name, description: $description, projectId: $project_id, status: $status, intervals: $intervals){
      track {
        id
        name
        intervals {
          edges {
            node {
              id
              description
            }
          }
        }
      }
      errors
    }
  }
`;
