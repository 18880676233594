import React, { useEffect, useState } from 'react';

function Timer({ status, setFormData, track, duration }) {
  const isActive = status == 'started';
  const [seconds, setSeconds] = useState(0);
  const { description, track_id } = track;
  const start = new Date();
  const formDataProps = {
    ...(!!description && { description }),
    ...(!!track_id && { track_id }),
    start_at: start.toISOString(),
  };

  useEffect(() => {
    let interval = null;

    if (isActive) {
      setFormData((prevState) => ({
        ...prevState,
        ...formDataProps,
      }));
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (seconds !== 0) {
      setSeconds(0);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const formatTime = (time) => {
    const pad = (num) => num.toString().padStart(2, '0');

    const hours = pad(Math.floor(time / 3600));
    const minutes = pad(Math.floor((time % 3600) / 60));
    const seconds = pad(time % 60);

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="text-centered">
      <h3 className="mx-4 down-button mt-custom">{formatTime(duration > 0 ? duration : seconds)}</h3>
    </div>
  );
}

export default Timer;
