import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';


const clientApollo = () => {
  const link = new HttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  });

  const client = new ApolloClient({ link: link, cache: new InMemoryCache() })

  return client;
};

export default clientApollo;
