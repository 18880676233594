export const INTERVAL_COLUMNS = {
  description: 1,
  startAt: 2,
  endAt: 3,
  date: 4,
};

export const CONSTANTS = {
  ERROR: 'error',
  WARNING: 'warning',
  FAILED_DESTROY_TRACK: 'Failed to destroy track',
  FAILED_UPDATE_TRACK: 'Failed to update track',
  FAILED_UPDATE_INTERVAL: 'Failed to update interval',
  FAILED_CREATE_INTERVAL: 'Failure trying create an Interval, check the values',
  TRACK_INVALID_INTERVAL: 'The end time can not be equal to the start time',
  TRACK_EMPTY_PROJECT: 'Project is required',
  TRACK_EMPTY_NAME: 'Name of track is required',
  SUCCESS: 'success',
  SUCCESSFULLY_UPDATE_TRACK: 'Successfully updated track',
  SUCCESSFULLY_UPDATE_INTERVAL: 'Successfully updated interval',
  SUCCESSFULLY_CREATE_INTERVAL: 'Successfully created interval',
  SUCCESSFULLY_DELETE_INTERVAL: 'Successfully deleted interval',
  TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  TITLE_WARNING: 'Could not update interval',
  TITLE_SAVING: 'Saving the data please wait a few seconds.',
  LOADING: 'loading',
  STARTED: 'started',
  REQUIRED_FIELDS_TRACK: 'Name, Description and Project are required',
  REQUIRED_FIELDS_INTERVAL: 'Description and Track are required',
  REQUIRED_DESCRIPTION: 'Description is required',
  REQUIRED_TRACK: 'Track is required',
  SUCCESSFULLY_CREATE_TRACK: 'Successfully created Track',
  CONFIRM_DELETE_BODY: 'Are you sure you want to delete this record? All its related information will be removed.',
  CONFIRM_DELETE_HEADER: 'Confirm delete'
};
