import React, { useState } from 'react';

import { useFetch } from '../../hooks/useFetch';
import { GET_TRACKS_WITH_PROJECTS } from '../../graphql/queries';
import LoadingSpinner from '../utils/Loading';
import IntervalForm from './IntervalForm';

const Track = ({ fetchTracksTable, track }) => {
  const { data, isLoading, refetch } = useFetch(GET_TRACKS_WITH_PROJECTS);

  return (
    <div className="row">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="col-lg-12">
          <div className="card shadow mb-10 border-bottom-primary">
            <div className="card-body d-flex">
              <IntervalForm
                data={data}
                fetchTracksTable={fetchTracksTable}
                fetchTracks={refetch}
                track={track}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Track;
