import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CONSTANTS } from '../../constants/Constants'

function DeleteModal({ handleDelete, open, setOpen, id }) {
  const closeBtn = (
    <button className="close" onClick={() => setOpen(false)} type="button">
      &times;
    </button>
  );

  return (
    <Modal isOpen={open}>
      <ModalHeader close={closeBtn}>
        {CONSTANTS.CONFIRM_DELETE_HEADER}
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          {CONSTANTS.CONFIRM_DELETE_BODY}
        </div>
      </ModalBody>
      <ModalFooter>
          <Button color="danger" onClick={() => handleDelete(id)}>
            Confirm
          </Button>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteModal;
