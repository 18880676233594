import React, { useState } from 'react';
import {
  Button, Input, Row, Col,
} from 'reactstrap';
import { FiCheck, FiX } from 'react-icons/fi';
import { useNotifications } from 'reapop';
import { CREATE_TRACK } from '../../graphql/queries';
import { CONSTANTS } from '../../constants/Constants';

function AddTrack({
  data, client, fetchTracks, handleDisplay, setCreating,
}) {
  const defaultTrack = {
    name: '',
    description: '',
    project_id: '',
  };

  const [track, setTrack] = useState(defaultTrack);
  const { notify } = useNotifications();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrack((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validTrack = (params) => {
    if (params.name !== '' && params.description !== '' && params.project_id !== '') {
      return true;
    }
    notify({
      title: CONSTANTS.FAILED_CREATE_TRACK,
      message: CONSTANTS.REQUIRED_FIELDS_TRACK,
      status: CONSTANTS.ERROR,
    });
    return false;
  };

  const createTrack = (request) => {
    client
      .mutate({ mutation: CREATE_TRACK, variables: request })
      .then((response) => {
        if (response?.data.createTrack.errors.length === 0) {
          notify(CONSTANTS.SUCCESSFULLY_CREATE_TRACK, CONSTANTS.SUCCESS);
          fetchTracks();
        } else {
          notify({
            title: CONSTANTS.FAILED_CREATE_TRACK,
            message: response.data.createTrack.errors,
            status: CONSTANTS.ERROR,
          });
        }
      })
      .catch((error) => notify({
        title: CONSTANTS.FAILED_CREATE_TRACK,
        message: error.toString(),
        status: CONSTANTS.ERROR,
      }))
      .finally(() => {
        setTrack(defaultTrack);
      });
  };

  const handleSubmit = () => {
    setCreating(false);
    if (validTrack(track)) {
      createTrack(track);
    }
  };

  const { projects } = data;

  const trackProps = {
    id: 'name',
    name: 'name',
    placeholder: 'Track name',
    type: 'text',
    className: 'rounded',
  };

  const descriptionProps = {
    id: 'description',
    name: 'description',
    placeholder: 'Description',
    type: 'text',
    className: 'rounded',
  };

  const projectProps = {
    id: 'project_id',
    name: 'project_id',
    type: 'select',
    className: 'rounded',
  };

  return (
    <div className="ml-4">
      <Row>
        <Col xs="12" md="4" className="custom-form-mobile mx-0 px-1">
          <Input {...trackProps} onChange={handleChange} />
        </Col>
        <Col xs="12" md="4" className="custom-form-mobile mx-0 px-1">
          <Input {...descriptionProps} onChange={handleChange} />
        </Col>
        <Col xs="12" md="2" className="custom-form-mobile mx-0 px-1">
          <Input {...projectProps} onChange={handleChange}>
            <option defaultValue> Select the project</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>{project.name}</option>
            ))}
          </Input>
        </Col>
        <Col xs="12" md="2" className="d-flex justify-content-center">
          <Button
            className="form-height btn-h-custom"
            color="success"
            onClick={handleSubmit}
          >
            <FiCheck />
          </Button>
          <Button
            className="form-height btn-h-custom ml-3"
            style={{
              height: '40px',
            }}
            onClick={handleDisplay}
          >
            <FiX />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddTrack;
