import React from 'react';

function LoadingSpinner({ loading = true }) {
  if (!loading) {
    return <div />;
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

LoadingSpinner.defaultProps = {
  containerStyle: {},
  style: {},
};

export default LoadingSpinner;
