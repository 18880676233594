import { ajax } from "../ajax";

var calendarEl = document.getElementById("calendar");
if (calendarEl !== null) {
  ajax("GET", "user/events", {}, functionCalendar);
}

function functionCalendar({ data }) {
  const locale = location.href.includes('/es') ? 'es' : 'en';

  let buttonText
  let today
  let noEventsMessage
  if (locale === 'en') {
    buttonText = {
      month:    'Month',
      week:     'Week',
      day:      'Day',
      list:     'List'
    }
    today =     'Today'
    noEventsMessage = 'No tracks to show'
  }
  else {
    buttonText = {
      month:    'Mes',
      week:     'Semana',
      day:      'Dia',
      list:     'Lista'
    }
    today =     'Hoy'
    noEventsMessage = 'No hay registros para mostrar'
  }
  let calendar = new FullCalendar.Calendar(calendarEl, {
    customButtons: {
      todayNew: {
        text: today,
        click: () => {
          calendar.today();
          addTodayClass();
        },
      },
      prevNew: {
        icon: "chevron-left",
        click: () => {
          calendar.prev();
          removeTodayClass();
        },
      },
      nextNew: {
        icon: "chevron-right",
        click: () => {
          calendar.next();
          removeTodayClass();
        },
      },
    },
    locale,
    headerToolbar: {
      left: "prevNew,todayNew,nextNew",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    buttonText: buttonText,
    weekNumbers: true,
    navLinks: true,
    editable: false,
    selectable: true,
    nowIndicator: true,
    dayMaxEvents: true,
    showNonCurrentDates: false,
    eventClick: function (info) {
      var e = info.event.extendedProps;
      $("#modalTitle").html(info.event.title);
      $("#modalBodyStart").html(e.start_at_time);
      $("#modalBodyEnd").html(e.end_at_time);
      $("#modalBodyDesc").html(e.description);
      $("#modalBodyProj").html(e.project);
      $("#eventsModal").modal();
    },
    events: data,
    noEventsContent: { html: '<span>' + noEventsMessage + '</span>' }
  });
  calendar.render();
  addTodayClass();
}

const dateTimeFormat = () => {
  return window.location.pathname.startsWith('/en') ? 'en-us' : 'es-ar'
};

const removeTodayClass = () => {
  $(".fc-todayNew-button").removeClass("fc-button-active");
};

const addTodayClass = () => {
  $(".fc-todayNew-button").addClass("fc-button-active");
};
