document.addEventListener("DOMContentLoaded", function () {
  const clearFilterBtn = document.getElementById("clear_filter_btn");
  try {
    clearFilterBtn.addEventListener("click", function () {
      const selects = document.querySelectorAll("select");
      for (var i = 0; i < selects.length; i++) {
        selects[i].value = "";
      }
      const inputs = document.querySelectorAll("input");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].value = "";
      }
      const searchField = document.querySelector("input[name='search']");
      searchField.value = "";
      const form = document.querySelector("form");
      form.submit();
    });
  } catch {
    return;
  }
});
