import { gql } from '@apollo/client';

export const UPDATE_INTERVAL_MUTATION = gql`
  mutation updateInterval(
    $id: ID!
    $description: String!
    $start_at: ISO8601DateTime!
    $end_at: ISO8601DateTime!
  ) {
    updateInterval(
      id: $id
      description: $description
      startAt: $start_at
      endAt: $end_at
    ) {
      interval {
        id
        description
        startAt
        endAt
        seconds
      }
      errors
    }
  }
`;

export const DELETE_INTERVAL_MUTATION = gql`
  mutation destroyInterval($id: ID!) {
    destroyInterval(id: $id) {
      interval {
        id
      }
      errors
    }
  }
`;

export const DELETE_TRACK_MUTATION = gql`
  mutation destroyTrack($id: ID!) {
    destroyTrack(id: $id) {
      track {
        id
      }
      errors
    }
  }
`;

export const UPDATE_TRACK_MUTATION = gql`
  mutation updateTrack(
    $id: ID!
    $name: String!
  ) {
    updateTrack(
      id: $id
      name: $name
    ) {
      track {
        id
        name
      }
      errors
    }
  }
`;
