import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

document.addEventListener("turbolinks:load", function () {
  const elements = document.querySelectorAll(".choices-multiple-remove-button");
  elements.forEach((element) => {
    const choices = new Choices(element, {
      removeItemButton: true,
      maxItemCount: 20,
      searchResultLimit: 50,
      renderChoiceLimit: 50,
      allowHTML: true,
    });
    choices.passedElement.element.addEventListener('addItem', () => {
      choices.hideDropdown()
    })
  });
});
