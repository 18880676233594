import React, { useState, useRef } from 'react';
import { useNotifications } from 'reapop';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { Icon } from './Utils';
import { FiTrash2, FiCalendar } from 'react-icons/fi';
import { localTime, utcDateTime, localDate, getCurrentTimeByTimeZone } from '../../packs/TimeZone.js';
import { updateInterval } from '../../graphql/client/updateInterval';
import { updateTrack } from '../../graphql/client/updateTrack';
import { destroyTrack } from '../../graphql/client/destroyTrack';
import DeleteModal from '../utils/DeleteModal';
import { INTERVAL_COLUMNS, CONSTANTS } from '../../constants/Constants';
import {
  UPDATE_INTERVAL_MUTATION,
  UPDATE_TRACK_MUTATION,
  DELETE_TRACK_MUTATION
} from '../../graphql/mutations/index';

const Track = ({
  track: {
    track_id: trackId,
    track_name: initialTrackName,
    intervals,
    project_name: projectName,
    time: time,
  },
  fetchTracksTable,
}) => {
  const interval = !!intervals.length ? intervals[0] : {};
  const startDate = !!intervals.length
    ? localTime(interval.start_at)
    : getCurrentTimeByTimeZone().format('DD-MM-YYYY HH:mm');
  const endDate = !!intervals.length
    ? localTime(interval.end_at)
    : getCurrentTimeByTimeZone().format('DD-MM-YYYY HH:mm');

  const { notify } = useNotifications();

  const [trackName, setTrackName] = useState(initialTrackName);
  const [prevTrackName, setPrevTrackName] = useState(trackName);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editableItemId, setEditableItemId] = useState(null);
  const [date, setDate] = useState(localDate(startDate, null, false));
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleItemClick = (id, index) => {
    setEditableItemId(id);
    setEditableIndex(index);
    setDate(localDate(interval.start_at, null, false));
    setStartAt(localTime(interval.start_at));
    setEndAt(localTime(interval.end_at));
    setTrackName(trackName);
  };

  const handleTimeChange = (event) => {
    if (editableIndex === INTERVAL_COLUMNS.startAt) {
      setStartAt(event.target.value);
    } else {
      setEndAt(event.target.value);
    }
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTrackNameChange = (event) => {
    setTrackName(event.target.value);
  };

  const dateOnBlur = (id) => {
    const oldDate = localDate(interval.start_at);
    const oldStartDateTime = utcDateTime(`${oldDate} ${startDate}`);
    const oldEndDateTime = utcDateTime(`${oldDate} ${endDate}`);

    const newDate = localDate(date);
    const startDateTime = utcDateTime(`${newDate} ${startAt}`);
    const endDateTime = utcDateTime(`${newDate} ${endAt}`);

    if (!oldStartDateTime.isSame(startDateTime) || !oldEndDateTime.isSame(endDateTime)) {
      changeDates(id, startDateTime, endDateTime);
    } else {
      setEditableItemId(null);
    }
  };

  const changeDates = (id, startDateTime, endDateTime) => {
    if (endDateTime.isBefore(startDateTime)) {
      endDateTime.add(1, 'day');
    }

    if (startDateTime.isBefore(endDateTime)) {
      const updatedData = {
        description: interval.description,
        start_at: startDateTime.toISOString(),
        end_at: endDateTime.toISOString(),
      };

      handleIntervalSave(id, updatedData);
    } else {
      notify({
        title: CONSTANTS.TITLE_WARNING,
        message: CONSTANTS.TRACK_INVALID_INTERVAL,
        status: CONSTANTS.WARNING,
      });
    }
  };

  const handleKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      dateOnBlur(id);
    }
  };

  const onBlur = () => {
    if (initialTrackName !== trackName) {
      handleTrackSave(trackId, trackName);
    } else {
      setEditableItemId(null);
    }
  };

  const handleTrackName = (e) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };

  const handleTrackSave = async (id, name) => {
    const notification = notify(CONSTANTS.TITLE_SAVING, CONSTANTS.LOADING, {
      dismissible: false,
    });
    setEditableIndex(null);

    try {
      const response = await updateTrack(UPDATE_TRACK_MUTATION, id, { name });

      if (response.errors.length) {
        setTrackName(prevTrackName);
        notify(CONSTANTS.FAILED_UPDATE_TRACK, CONSTANTS.ERROR);
      } else {
        setPrevTrackName(name);
        setTrackName(name);

        setTimeout(() => {
          notification.status = CONSTANTS.SUCCESS;
          notification.message = CONSTANTS.SUCCESSFULLY_UPDATE_TRACK;
          notify(notification);
        }, 1000);
      }

      fetchTracksTable();
    } catch (error) {
      notify(CONSTANTS.FAILED_UPDATE_TRACK, CONSTANTS.ERROR);
    }
  };

  const handleIntervalSave = async (id, { description, start_at, end_at }) => {
    const notification = notify(CONSTANTS.TITLE_SAVING, CONSTANTS.LOADING, {
      dismissible: false,
    });
    setEditableIndex(null);

    try {
      const response = await updateInterval(UPDATE_INTERVAL_MUTATION, id, {
        description,
        start_at,
        end_at,
      });

      if (response.errors.length) {
        notify(CONSTANTS.FAILED_UPDATE_INTERVAL, CONSTANTS.ERROR);
      } else {
        setTimeout(() => {
          notification.status = CONSTANTS.SUCCESS;
          notification.message = CONSTANTS.SUCCESSFULLY_UPDATE_INTERVAL;
          notify(notification);
        }, 1000);
      }
      fetchTracksTable();
    } catch (error) {
      notify(CONSTANTS.FAILED_UPDATE_INTERVAL, CONSTANTS.ERROR);
    }
  };

  const handleDelete = async (id) => {
    const notification = notify(CONSTANTS.TITLE_SAVING, CONSTANTS.LOADING, {
      dismissible: false,
    });

    try {
      const response = await destroyTrack(DELETE_TRACK_MUTATION, id);

      if (response.errors.length) {
        notify(CONSTANTS.FAILED_DESTROY_TRACK, CONSTANTS.ERROR);
      } else {
        setTimeout(() => {
          notification.status = CONSTANTS.SUCCESS;
          notification.message = CONSTANTS.SUCCESSFULLY_DELETE_INTERVAL;
          notify(notification);
        }, 1000);
        fetchTracksTable();
      }

    } catch (error) {
      console.error(error);
      notify(CONSTANTS.FAILED_DESTROY_TRACK, CONSTANTS.ERROR);
    }
  };

  return (
    <ListGroup horizontal className="track-list">
      <Row className='w-100 table-row'>
        <Col md="12" lg="6" className='d-flex pr-lg-0 track-list-first'>
          <ListGroupItem className="d-flex w-75 list-border-tracks align-items-center">
            {editableItemId === trackId ? (
              <input
                type="text"
                className="form-control input-name"
                value={trackName}
                onChange={handleTrackNameChange}
                onBlur={onBlur}
                onKeyDown={(e) => handleTrackName(e)}
                ref={inputRef}
                autoFocus
              />
            ) : (
              <div
                className="pointer text-truncate"
                onClick={() => handleItemClick(trackId, 1)}
              >
                {trackName}
              </div>
            )}
          </ListGroupItem>
          <ListGroupItem className="d-flex w-25 justify-content-center align-items-center list-border-tracks">
            <div className="time">{time}</div>
          </ListGroupItem>
        </Col>

        <Col md="12" lg="3" className='d-flex px-lg-0 track-list-second'>
          <ListGroupItem className="d-flex list-border-tracks align-items-center px-lg-0">
            <div className='text-center'>{projectName}</div>
          </ListGroupItem>
          <ListGroupItem className="d-flex list-border-tracks align-items-center px-lg-0 justify-content-center">
            {editableItemId === interval.id && editableIndex === INTERVAL_COLUMNS.date ? (
              <input
                type="date"
                className="form-control input-calendar"
                value={date}
                onChange={handleDateChange}
                onBlur={() => dateOnBlur(interval.id)}
                onKeyDown={(e) => handleKeyPress(e, interval.id)}
                ref={inputRef}
                autoFocus
              />
            ) : (
              <Icon className="w-100">
                <FiCalendar
                  onClick={() => handleItemClick(interval.id, INTERVAL_COLUMNS.date)}
                />
              </Icon>
            )}
          </ListGroupItem>
        </Col>

        <Col md="12" lg="3" className='d-flex pl-lg-0 track-list-third'>
          <ListGroupItem className="d-flex list-border-tracks align-items-center px-lg-0 w-75 gap-5">
            {editableItemId === interval.id && editableIndex === INTERVAL_COLUMNS.startAt ? (
              <input
                type="time"
                className="form-control input-timer pointer w-50"
                value={startAt}
                onChange={handleTimeChange}
                onBlur={() => dateOnBlur(interval.id)}
                onKeyDown={(e) => handleKeyPress(e, interval.id)}
                ref={inputRef}
                autoFocus
              />
            ) : (
              <div
                className="pointer w-50"
                onClick={() => handleItemClick(interval.id, INTERVAL_COLUMNS.startAt)}
              >
                {startDate}
              </div>
            )}
            {editableItemId === interval.id && editableIndex === INTERVAL_COLUMNS.endAt ? (
              <input
                type="time"
                className="form-control pointer w-50 input-timer"
                value={endAt}
                min={startAt}
                onChange={handleTimeChange}
                onBlur={() => dateOnBlur(interval.id)}
                onKeyDown={(e) => handleKeyPress(e, interval.id)}
                ref={inputRef}
                autoFocus
              />
            ) : (
              <div
                className="pointer w-50"
                onClick={() => handleItemClick(interval.id, INTERVAL_COLUMNS.endAt)}
              >
                {endDate}
              </div>
            )}
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-center align-items-center list-border-tracks px-lg-0 w-25">
            <Icon className="w-100">
              <FiTrash2 title="Delete" onClick={() => setOpen(true)} />
            </Icon>
          </ListGroupItem>
        </Col>
      </Row>
      <DeleteModal open={open} setOpen={setOpen} handleDelete={handleDelete} id={trackId} />
    </ListGroup>
  );
};

export default Track;
