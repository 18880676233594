import { useState, useEffect } from 'react';
import clientApollo from '../graphql/client';

export function useFetch(QUERY) {
  const client = clientApollo();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    client
      .query({ query: QUERY })
      .then((response) => response.data.user)
      .then((data) => setData(data))
      .catch((error) => console.log('error', error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500)
  };

  return { data, isLoading, refetch };
}
