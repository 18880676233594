import React from 'react';
import Weeks from './Weeks';

const TrackerTable = ({ data, setTrack, fetchTracksTable }) => {
  return data?.weeks?.data?.length ? (
    <Weeks
      data={data.weeks.data}
      setTrack={setTrack}
      fetchTracksTable={fetchTracksTable}
    />
  ) : null;
}

export default TrackerTable;
