import React from 'react';
import Track from './Track';

const Tracks = ({ tracks, fetchTracksTable }) => {
  return tracks.map(track => (
    <Track
      key={track.track_id}
      track={track}
      fetchTracksTable={fetchTracksTable}
    />
  ));
};

export default Tracks;
