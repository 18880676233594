import React from 'react';
import moment from 'moment';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Tracks from './Tracks';

const Days = ({ days, setTrack, fetchTracksTable }) => {
  return days.map(({ date, time, tracks }, index) => (
    <Card className="d-flex w-100 mb-2 mt-2" key={index}>
      <CardHeader className="header-card">
        <div className="d-flex justify-content-between">
          <div>{moment(date).format('ddd, Do MMM')}</div>
          <div>
            Total:
            {' '}
            <span className="time">{time}</span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Tracks
          tracks={tracks}
          setTrack={setTrack}
          fetchTracksTable={fetchTracksTable}
        />
      </CardBody>
    </Card>
  ));
};

export default Days;
