import React, { useState, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';
import moment from 'moment';
import { useNotifications } from 'reapop';
import {
  Input, InputGroup, Form, Button,
} from 'reactstrap';
import Timer from './Timer';
import { CREATE_TRACK_WITH_INTERVAL } from '../../graphql/queries';
import clientApollo from '../../graphql/client';
import { CONSTANTS } from '../../constants/Constants';
import { utcDateTime, getCurrentTimeByTimeZone } from '../../packs/TimeZone.js';

const IntervalForm = ({ data, fetchTracksTable, track }) => {
  const currentHour = getCurrentTimeByTimeZone().format('HH:mm');

  const client = clientApollo();
  const { notify } = useNotifications();
  const [formData, setFormData] = useState(track);
  const [date, setDate] = useState(getCurrentTimeByTimeZone().format('YYYY-MM-DD'));
  const [endAt, setEndAt] = useState(currentHour);
  const [startAt, setStartAt] = useState(currentHour);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  const { projects = [] } = data || {};

  useEffect(() => {
    const duration = calculateDuration(startAt, endAt);
    (duration >= 0) ? setDuration(duration) : setDuration(0)
  }, [startAt, endAt]);

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    name === 'start_at' ? setStartAt(value) : setEndAt(value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const calculateDuration = () => {
    const startTime = utcDateTime(`${date} ${startAt}`);
    const endTime = utcDateTime(`${date} ${endAt}`);

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    const durationMs = endTime - startTime;

    return Math.floor(durationMs / 1000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    saveNewInterval();
  };

  let loadErrorMessages = (errorMessages) => {
    const { startDate, endDate } = intervalDates();
    if (!formData.name){
      errorMessages.push(CONSTANTS.TRACK_EMPTY_NAME) ;
    }

    if (!formData.project_id){
      errorMessages.push(CONSTANTS.TRACK_EMPTY_PROJECT) ;
    }

    if (!startDate.isBefore(endDate)) {
      errorMessages.push(CONSTANTS.TRACK_INVALID_INTERVAL) ;
    }
  }

  const saveNewInterval = () => {
    let errorMessages = [];

    loadErrorMessages(errorMessages)

    if (errorMessages.length == 0) {
      createInterval();
    } else {
      const errorMessage = errorMessages.join('<br />');

      notify({
        title: CONSTANTS.TITLE_WARNING,
        message: <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
        status: CONSTANTS.WARNING,
        dismissAfter: 5000
      });
      setLoading(false);
    }
  };

  const createInterval = () => {
    const notification = notify(CONSTANTS.TITLE_SAVING, CONSTANTS.LOADING, {
      dismissible: false,
    });

    client
    .mutate({ mutation: CREATE_TRACK_WITH_INTERVAL, variables: normalizeDateTimeRange() })
    .then((response) => {
      if (response.data.createTrack.errors.length === 0) {
        notification.status = CONSTANTS.SUCCESS;
        notification.message = CONSTANTS.SUCCESSFULLY_CREATE_INTERVAL;
        notification.dismissAfter = 1000;
        notify(notification);
        fetchTracksTable();
      } else {
        notify({
          title: CONSTANTS.FAILED_CREATE_INTERVAL,
          message: response.data.createTrack.errors.join(', '),
          status: CONSTANTS.ERROR,
          dismissAfter: 1000
        });
        setTimeout(() => {
          setLoading(false);
        }, 4000)
      }
    })
    .catch((error) => {
      notify(error, CONSTANTS.ERROR)
    });
  };

  const normalizeDateTimeRange = () => {
    const { startDate, endDate } = intervalDates();

    return {
      ...formData,
      description: formData.name,
      status: 'finished',
      intervals: [{
        description: formData.name,
        start_at: startDate.toISOString(),
        end_at: endDate.toISOString(),
      }]
    };
  };

  const intervalDates = () => {
    const startDate = utcDateTime(`${moment(date).format('DD-MM-YYYY')} ${startAt}`);
    const endDate = utcDateTime(`${moment(date).format('DD-MM-YYYY')} ${endAt}`);

    if (endDate.isBefore(startDate)) {
      endDate.add(1, 'day');
    }
    return { startDate, endDate };
  };

  const nameProps = {
    id: 'name',
    name: 'name',
    className: 'form-control',
    type: 'text',
    placeholder: 'Name',
    ...(track.name !== '' && { value: track.name }),
  };

  const projectProps = {
    id: 'project_id',
    name: 'project_id',
    type: 'select',
    hidden: false,
    ...(track.project_id !== '' && { value: track.project_id }),
  };

  return (
    <Form onSubmit={handleSubmit} className='w-100'>
      <div className="d-flex justify-content-between mt-3 mb-3 mr-4 ml-4 interval-form">
        <InputGroup className='mb-2'>
          <Input {...nameProps} onChange={handleChange} />
          <Input {...projectProps} onChange={handleChange}>
            <option defaultValue value=''> Select the project</option>
            {projects.map(project => <option key={project.id} value={project.id}>{project.name}</option>)}
          </Input>
        </InputGroup>

        <InputGroup className='mb-2'>
          <input
            type="time"
            name="start_at"
            className="form-control"
            value={startAt}
            onChange={handleTimeChange}
            autoFocus
          />
          <input
            type="time"
            name="end_at"
            className="form-control"
            value={endAt}
            onChange={handleTimeChange}
            autoFocus
          />
        </InputGroup>
        <InputGroup className='mb-2'>
          <input
            type="date"
            name="date"
            className="form-control"
            value={date}
            onChange={handleDateChange}
            autoFocus
          />
        </InputGroup>
        <Timer setFormData={setFormData} track={track} duration={duration} />
        <Button
          type="button"
          color="success"
          className="form-height btn-h-custom"
          onClick={handleSubmit}
          hidden={loading}
        >
          <FiPlus />
        </Button>
      </div>
    </Form>
  );
}

export default IntervalForm;
